import React, { useEffect, useState } from "react"
import qs from "query-string"

import OrderDone from "../../components/checkout/done"
import SEO from "../../components/seo"
import LoadingSpinner from "../../components/ui/LoadingSpinner"
import { trackPurchase } from "../../services/analytics"
import Medusa from "../../services/api"
import { useProducts } from "medusa-react"
import { Cart } from "@medusajs/medusa"

const CheckoutDoneSplitOrder = ({ location }) => {
  const [splitOrderCart, setSplitOrderCart] = React.useState<Cart | undefined>()
  const [fetchingOrder, setFetchingOrder] = useState(true)

  const { products, isLoading } = useProducts(
    {
      id: splitOrderCart?.items?.map((i) => i?.variant?.product_id),
      expand: "categories,type",
    },
    {
      enabled: Boolean(splitOrderCart?.items?.length),
      keepPreviousData: true,
    }
  )

  useEffect(() => {
    const initializeOrders = async () => {
      const { so } = qs.parse(location.search)

      if (so) {
        try {
          const { data } = await Medusa.cart.retrieve(so)

          setSplitOrderCart(data.cart)
          setFetchingOrder(false)
        } catch (error) {
          console.error(error)
          setFetchingOrder(false)
        }
      }
    }
    initializeOrders()
  }, [location.search])

  useEffect(() => {
    if (!splitOrderCart || !products?.length || !splitOrderCart?.items?.length)
      return
    const tokens = JSON.parse(localStorage.getItem("ots")) || []

    if (!tokens.includes(splitOrderCart.id)) {
      tokens.push(splitOrderCart.id)
      trackPurchase({
        ...splitOrderCart,
        items: splitOrderCart?.items?.map((item) => {
          return {
            ...item,
            category: "",
          }
        }),
      })
    }
    localStorage.setItem("ots", JSON.stringify(tokens))
  }, [splitOrderCart, products])

  return (
    <>
      <SEO title="Order Completed" />
      {!splitOrderCart?.id || fetchingOrder ? (
        <LoadingSpinner />
      ) : (
        splitOrderCart && (
          <>
            <OrderDone order={splitOrderCart} isSplitOrder={true} />
          </>
        )
      )}
    </>
  )
}

export default CheckoutDoneSplitOrder
